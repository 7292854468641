/*https://css.gg/*/

.gg-chevron-right {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px
}
.gg-chevron-right::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(-45deg);
    right: 6px;
    top: 4px
}
.gg-chevron-left {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px
}
.gg-chevron-left::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-left: 2px solid;
    transform: rotate(45deg);
    left: 6px;
    top: 4px
}


.gg-check {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-radius: 100px
}
.gg-check::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg)
}

.gg-close {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-radius: 40px
}
.gg-close::after,
.gg-close::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 1px
}
.gg-close::after {
  transform: rotate(-45deg)
}

.gg-list {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 20px;
  border: 2px solid;
  border-radius: 3px
}
.gg-list::after,
.gg-list::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 2px;
  height: 2px;
  background: currentColor;
  top: 3px;
  left: 3px;
  box-shadow:
      0 4px 0,
      0 8px 0
}
.gg-list::after {
  border-radius: 3px;
  width: 8px;
  left: 7px
}

.gg-pin {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs,1));
  width: 18px;
  height: 18px;
  border-radius: 100% 100% 0 100%;
  border: 2px solid;
  margin-top: -4px
}
.gg-pin::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid;
  top: 3px;
  left: 3px;
  border-radius: 40px
}

.gg-danger {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 40px
}
.gg-danger::after,
.gg-danger::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  background: currentColor;
  left: 7px
}
.gg-danger::after {
  top: 2px;
  height: 8px
}
.gg-danger::before {
  height: 2px;
  bottom: 2px
}
.gg-ghost-character {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 14px;
  height: 18px;
  border: 2px solid;
  border-bottom: 0;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  overflow: hidden;
  background:
  radial-gradient(
  circle,currentColor 60%,
  transparent 40%)
  no-repeat 2px 4px/2px 2px,
  radial-gradient(
  circle,currentColor 60%,
  transparent 40%)
  no-repeat 6px 4px/2px 2px
 }
 
 .gg-ghost-character::after,
 .gg-ghost-character::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  bottom: -3px;
  transform: rotate(-45deg)
 }
 
 .gg-ghost-character::before {
  left: -1px
 }
 
 .gg-ghost-character::after {
  right: -1px
 } 

.gg-menu-left {
  transform: scale(var(--ggs,1))
}
.gg-menu-left,
.gg-menu-left::after,
.gg-menu-left::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background: currentColor
}
.gg-menu-left::after,
.gg-menu-left::before {
  content: "";
  position: absolute;
  top: -6px;
  width: 10px
}
.gg-menu-left::after {
  top: 6px;
  width: 14px
}

.gg-shape-rhombus {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs,1));
  width: 14px;
  height: 14px;
  border: 3px solid
}

.gg-phone {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  transform: scale(var(--ggs,1))
}
.gg-phone::after,
.gg-phone::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute
}
.gg-phone::after {
  width: 18px;
  height: 18px;
  border-top-left-radius: 1px;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 12px;
  border-left: 4px solid;
  border-bottom: 4px solid;
  left: 2px;
  bottom: 2px;
  background:
     linear-gradient(
        to left,
        currentColor 10px,
        transparent 0
     ) no-repeat right 11px/6px 4px,
     linear-gradient(
        to left,
        currentColor 10px,
        transparent 0
     ) no-repeat -1px 0/4px 6px
}
.gg-phone::before {
  width: 20px;
  height: 20px;
  border: 6px double;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
  transform: rotate(-45deg);
  bottom: 2px;
  left: 2px
}

.lds-grid {
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $grey;
    animation: lds-grid 1.2s linear infinite;
  }
  .lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  .lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
  }
  @keyframes lds-grid {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  

  /*Star rating*/
.h_review_rating_score{
    font-size:12px;
    padding:0 5px;
}
.h_review_rating {
    unicode-bidi: bidi-override;
    direction: ltr;
    color:#ffcc48;
    font-size: 1.4em;
    line-height: 30px;
}
.h_review_rating > span {
    display: inline-block;
    position: relative;
}
.h_review_rating > span.fill:before {
    content: "\2605";
    position: absolute;
}

.h_review_rating_score{
    padding-top: 2px;
}

.spinner {
  width: 15px;
  height: 15px;
  position: relative;
  vertical-align: middle;
  display: inline-block;
  margin-top: 3px;
  margin-right: 6px;
  
  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $grey;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

}

/*small loader*/
.tiny_loader{
  font-size:0.8em;
  
  .text{
    vertical-align: middle;
    display: inline-block;
  }


  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
}